






import Vue from "vue";
import { Component } from "vue-property-decorator";
// import VConsole from "vconsole";
import liff from "@line/liff";

@Component
export default class App extends Vue {
  // ページを開いた時に実行
  async mounted() {
    // this.initVConsole();
    //LINEブラウザかどうか確認
    // if(liff.isInClient() == false) {
    //   window.alert("LINEでのみ開くことが可能です。");
    //   window.close();
    //   return;
    // }
  }

  // initVConsole() {
  //   const vConsole = new VConsole();
  // }
}
