import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },  
  {
    path: "/reserve",
    component: () => import("@/views/Reserve.vue"),
  },
  {
    path: "/report",
    component: () => import("@/views/Report.vue"),
  },
  {
    path: "/garbagesetting",
    component: () => import("@/views/GarbageSetting.vue"),
  },
  {
    path: "/garbagecalendar",
    component: () => import("@/views/GarbageCalendar.vue"),
  },
  {
    path: "/about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/auth",
    component: () => import("@/views/AuthForm.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
